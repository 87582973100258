import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Calendar, CalendarModule } from 'primeng/calendar';
import {
  AbstractControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgModel,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { ControlValue } from '../../utiles/ControlValue';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { BotonComponent } from '@destinux/destinux-components';
import { Ifecha } from '../../interfaces/calendario';
import { IconoSvgComponent } from '../icono-svg/icono-svg.component';
import { Icon } from '../../iconos/iconos';
import { InformacionComponent } from '../informacion/informacion.component';
import { ETipoInformacion } from '../../enums/informacion';
import { EnumsLib } from '../../utiles/enums';
import { NotificacionComponent } from '../../../../../libs/destinux-components/src/components/notificacion/notificacion.component';
import * as dayjs from 'dayjs';

@Component({
  selector: 'destinux-calendar',
  standalone: true,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CalendarComponent),
      multi: true,
    },
  ],
  imports: [
    CommonModule,
    CalendarModule,
    FormsModule,
    BotonComponent,
    IconoSvgComponent,
    InformacionComponent,
    NotificacionComponent,
  ],
})
export class CalendarComponent
  extends ControlValue
  implements OnInit, Validator, AfterViewInit
{
   //#region contentChild
   @ContentChild('footer', { static: false }) footer!: TemplateRef<any>;
   //#endregion
   
  //#region viewChild
  @ViewChild('calendar') calendar!: Calendar;
  @ViewChild('calendario', { read: NgModel }) calendario!: NgModel;
  //#endregion

  //#region inputs
  /** se indica si se quiere seleccionar las fechas por rango, en caso de ser true, se puede seleccionar una fecha de inicio y una de fin
   * opcional
   */
  @Input() set conRango(val: boolean) {
    this.conRangoChange.emit(val);
    this._conRango = val;
    this.rango = this.incializadorRango();
  }
  get conRango() {
    return this._conRango;
  }
  /** texto que ira en el boton de la fecha inicial
   * opcional
   */
  @Input() textoBotonInicio: string = 'IDA'; //TODO traducir
  /** texto que ira en el boton de la fecha final
   * opcional
   */
  @Input() textoBotonFin: string = 'VUELTA'; //TODO traducir
  /** texto que ira en el footer del calendario, al lado de la cantidad de dias */
  @Input() textoFooter: string = 'DÍAS'; //TODO traducir
  /** indica si se calculara por día o por noche, para ser mostrado en el footer del calendario */
  @Input() isDia: boolean = true;
  /** para saber cual es la fecha minima que se puede seleccionar */
  @Input() minDate: Date = null as unknown as Date;
  /** fecha maxima que puede seleccionar el usuario, opcional e inicializado a null como valor por defecto por coherencia con primeNg (Date) */
  @Input() maxDate: Date = null as unknown as Date;
  /** para señalar si se desea mostrar el año */
  @Input() verAnyo: boolean = false;

  @Input() claseBoton: string = '';
  /** para mostrar botón de vacíar fecha (limpiar dato) */
  @Input() canClearDate: boolean = false;
  @Input() claseBotonFechas: string = '';
  /** si es true no deja seleccionar una fecha anterior al día de hoy */
  @Input() valid: boolean = true;
  @Input() validFechaActual: boolean = true;
  @Input() numeroMeses: number = 2;
  /** si hay que mostrar la hora al usuario porque es necesario seleccionarla  */
  @Input() hora: boolean = false;
  @Input() fechaInicio: Date | null = null;
  @Input() mensajeErrorInicio: string = "Cuéntanos cuándo vas a llegar";
  @Input() mensajeErrorFin: string = "Cuéntanos cuándo vas a volver";
  //#endregion

  //#region output
  @Output() conRangoChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  //#endregion

  //#region declaración de variables
  private _conRango: boolean = false;
  public mesesAMostrar: number = 1;
  public inicio: Ifecha = this.inicializadorFecha();
  public fin: Ifecha = this.inicializadorFecha();
  private mesCorto: { [mes: number]: string };
  public rango: any = this.incializadorRango();
  public dias: number = 0;
  #resolucion1: string = '1225px';
  #resolucion2: string = '796px';

  public responsiveCalendarOptions: any[] = [];
  public Icon = Icon;
  private hoy: Date = new Date();

  public ETipoInformacion = ETipoInformacion;
  public enums = EnumsLib;
  public mostrarToast: boolean = false;
  public mostrarFecha: boolean = false;
  public mostrarBorrar: boolean = false;
  public mostrarFechaFin: boolean = false;
  //#endregion

  //#region declaración de lenguajes
  private es: any;

  //#endregion

  //#region constructor
  constructor(
    private primengConfig: PrimeNGConfig,
    private _translateService: TranslateService
  ) {
    super();
    this.mesCorto = {
      0: this._translateService.instant('shared.mes.corto.enero').toUpperCase(),
      1: this._translateService
        .instant('shared.mes.corto.febrero')
        .toUpperCase(),
      2: this._translateService.instant('shared.mes.corto.marzo').toUpperCase(),
      3: this._translateService.instant('shared.mes.corto.abril').toUpperCase(),
      4: this._translateService.instant('shared.mes.corto.mayo').toUpperCase(),
      5: this._translateService.instant('shared.mes.corto.junio').toUpperCase(),
      6: this._translateService.instant('shared.mes.corto.julio').toUpperCase(),
      7: this._translateService
        .instant('shared.mes.corto.agosto')
        .toUpperCase(),
      8: this._translateService
        .instant('shared.mes.corto.septiembre')
        .toUpperCase(),
      9: this._translateService
        .instant('shared.mes.corto.octubre')
        .toUpperCase(),
      10: this._translateService
        .instant('shared.mes.corto.noviembre')
        .toUpperCase(),
      11: this._translateService
        .instant('shared.mes.corto.diciembre')
        .toUpperCase(),
    };
    this.es = {
      closeText: this._translateService
        .instant('admin.comun.cerrar-modal')
        .toUpperCase(),
      prevText: this._translateService
        .instant('admin.calendar.anterior')
        .toUpperCase(),
      nextText: this._translateService
        .instant('admin.calendar.siguiente')
        .toUpperCase(),
      monthNames: [
        this._translateService.instant('shared.mes.entero.enero').toUpperCase(),
        this._translateService
          .instant('shared.mes.entero.febrero')
          .toUpperCase(),
        this._translateService.instant('shared.mes.entero.marzo').toUpperCase(),
        this._translateService.instant('shared.mes.entero.abril').toUpperCase(),
        this._translateService.instant('shared.mes.entero.mayo').toUpperCase(),
        this._translateService.instant('shared.mes.entero.junio').toUpperCase(),
        this._translateService.instant('shared.mes.entero.julio').toUpperCase(),
        this._translateService
          .instant('shared.mes.entero.agosto')
          .toUpperCase(),
        this._translateService
          .instant('shared.mes.entero.septiembre')
          .toUpperCase(),
        this._translateService
          .instant('shared.mes.entero.octubre')
          .toUpperCase(),
        this._translateService
          .instant('shared.mes.entero.noviembre')
          .toUpperCase(),
        this._translateService
          .instant('shared.mes.entero.diciembre')
          .toUpperCase(),
      ],
      monthNamesShort: [
        this._translateService
          .instant('shared.mes.corto.enero')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.mes.corto.febrero')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.mes.corto.marzo')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.mes.corto.abril')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.mes.corto.mayo')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.mes.corto.junio')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.mes.corto.julio')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.mes.corto.agosto')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.mes.corto.septiembre')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.mes.corto.octubre')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.mes.corto.noviembre')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.mes.corto.diciembre')
          .toLocaleUpperCase(),
      ],
      dayNames: [
        this._translateService
          .instant('shared.dias.entero.domingo')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.dias.entero.lunes')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.dias.entero.martes')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.dias.entero.miercoles')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.dias.entero.jueves')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.dias.entero.viernes')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.dias.entero.sabado')
          .toLocaleUpperCase(),
      ],
      dayNamesShort: [
        this._translateService
          .instant('shared.dias.corto.domingo')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.dias.corto.lunes')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.dias.corto.martes')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.dias.corto.miercoles')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.dias.corto.jueves')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.dias.corto.viernes')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.dias.corto.sabado')
          .toLocaleUpperCase(),
      ],
      dayNamesMin: [
        this._translateService
          .instant('shared.dias.min.domingo')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.dias.min.lunes')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.dias.min.martes')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.dias.min.miercoles')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.dias.min.jueves')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.dias.min.viernes')
          .toLocaleUpperCase(),
        this._translateService
          .instant('shared.dias.min.sabado')
          .toLocaleUpperCase(),
      ],
      weekHeader: this._translateService
        .instant('admin.calendar.semana')
        .toLocaleUpperCase(),
      firstDay: 1,
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix: '',
      timeOnlyTitle: 'Sólo hora', //TODO traducir
      timeText: 'Tiempo', //TODO traducir
      hourText: 'Hora', //TODO traducir
      minuteText: 'Minuto', //TODO traducir
      secondText: 'Segundo', //TODO traducir
      currentText: 'Fecha actual', //TODO traducir
      ampm: false,
      month: this._translateService
        .instant('admin.calendar.mes')
        .toLocaleUpperCase(),
      week: this._translateService
        .instant('admin.calendar.semana')
        .toLocaleUpperCase(),
      day: this._translateService
        .instant('admin.calendar.dia')
        .toLocaleUpperCase(),
      allDayText: 'Todo el día', //TODO traducir
    };
    this.primengConfig.setTranslation(this.es);
  }
  //#endregion

  //#region OnInit
  ngOnInit(): void {
    this.rango = this.incializadorRango();
    this.responsiveCalendarOptions = [
      { breakpoint: this.#resolucion2, numMonths: 1 },
      { breakpoint: this.#resolucion1, numMonths: 2 },
    ];
  }
  //#endregion

  ngAfterViewInit(): void {
    if (!this.conRango) {
      this.value = new Date();
      this.value.setHours(9);
      this.value.setMinutes(0);
    }
  }

  //#region validator
  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    if (this.valid) {
      const fechaActual = new Date();
      if(!this.hora){
        this.seterHoras(fechaActual, 0);
      }
      const fechas = control.value;
      let fechaInicio = new Date();

      if (fechas) {
        fechaInicio = this.conRango ? fechas[0] : fechas;
        // fecha hoy es mayor a la fecha seleccionada
        if (fechaActual > fechaInicio && this.validFechaActual) {
          control.setValue(undefined);
          this.mostrarToast = true;
          return { fechasValidador: true };
        // resto de casos (igual o menor)
        } else {
          this.mostrarToast = false;
        }
        if (this.conRango) {
          if (fechas[1]) {
            if (fechaActual > fechas[1] && this.validFechaActual) {
              control.setValue(undefined);
              return { fechasValidador: true };
            } else if (fechas[1] <= fechas[0]) {
              return { fechasValidador: true };
            }
          } else {
            return { fechasValidador: true };
          }
        }
      }
    }
    return null;
  }
  //#endregion

  //#region metodos inicialización
  inicializadorFecha(): Ifecha {
    return { dia: '', mes: '', anyo: '', mesCorto: '' };
  }

  incializadorRango() {
    return this.conRango ? 'range' : 'single';
  }

  seterHoras(fecha: Date, hora: number) {
    fecha.setHours(hora);
    fecha.setMinutes(hora);
    fecha.setSeconds(hora);
    fecha.setMilliseconds(hora);
  }
  //#endregion

  //#region metodos usados en el html
  entreFechas(date: any){
    const fecha = new Date();
    fecha.setDate(date.day);
    fecha.setMonth(date.month);
    fecha.setFullYear(date.year);

    if(this.value && this.value.length > 1){
      if(fecha > this.value[0] && this.value[1] > fecha){
        return true;
      }
    }
    return false;
  }

  override writeValue(value: any): void {
    if (value) {
      if (!this.hora) {
        this.seterHoras(this.hoy, 0);
      }
      // fecha menor a hoy
      if (this.validFechaActual && ((!this.conRango && value < this.hoy) || (this.conRango && (value[0] < this.hoy || value[1] < this.hoy)))
      ) {
        this.value = undefined;
        this.inicio = this.inicializadorFecha();
        this.fin = this.inicializadorFecha();
        this.mostrarFecha = false;
        this.mostrarFechaFin = false;
        this.mostrarBorrar = false;
      } 
      // fecha igual o mayor que hoy, cuando se cumple la condición
      else if (!this.conRango || (this.conRango && value.length > 0)) {
        this.value = value;
        this.click();
      }
    // resto de casos
    } else {
      this.value = undefined;
      this.inicio = this.inicializadorFecha();
      this.fin = this.inicializadorFecha();
      this.mostrarBorrar = false;
      this.mostrarFecha = false;
      this.mostrarFechaFin = false;
    }
  }

  hndInfoError() {
    if (this.claseBoton.includes('error')) {
      return true;
    }
    return false;
  }

  hndClick(): void {
    this.click();
  }

  click(): void {
    if (this.value) {
      if (this.isDia) {
        this.dias = 1;
      } else {
        this.dias = 0;
      }

      if (this.canClearDate) {
        this.mostrarBorrar = true;
      }

      this.inicio = this.inicializadorFecha();
      this.fin = this.inicializadorFecha();

      const valorInicio =
        this.conRango && this.value.length ? this.value[0] : this.value;

      this.inicio.dia = valorInicio.getDate();
      this.inicio.mes = valorInicio.getMonth();
      this.inicio.anyo = valorInicio.getFullYear();
      this.inicio.mesCorto = this.mesCorto[Number.parseInt(this.inicio.mes)];

      if (this.conRango) {
        if (this.value[1] !== null) {
          const dia1 = valorInicio;
          const dia2 = this.value[1];
          if (!this.hora) {
            this.seterHoras(dia1, 0);
            this.seterHoras(dia2, 0);
          }
          this.dias = dayjs(dia2).diff(dia1, 'day');

          if (this.isDia) {
            this.dias += 1;
          }
          this.mostrarFechaFin = true;
        } else {
          this.mostrarBorrar = false;
          this.mostrarFechaFin = false;
        }

        this.fin.dia = this.value[1]?.getDate();
        this.fin.mes = this.value[1]?.getMonth();
        this.fin.anyo = this.value[1]?.getFullYear();
        this.fin.mesCorto = this.mesCorto[Number.parseInt(this.fin.mes)];

        if (this.hora) {
          this.fin.hora = this.value[1];
        }
      }

      if (this.hora) {
        this.inicio.hora = valorInicio;
      }

      this.mostrarFecha = true;
    }
  }

  openCalendar(): void {
    this.calendar.showOverlay();
    this.calendar.cd.detectChanges();
  }

  close(): void {
    this.calendar.overlayVisible = false;
  }

  hndEliminarFecha(fin:boolean): void {
    if (this.conRango) {
      if(fin){
        this.mostrarFechaFin = false;
      }
      this.fin = this.inicializadorFecha();
      if (this.value && this.value[1]) {
        this.value[1] = undefined;
      }
      if (this.isDia) {
        this.dias = 1;
      } else {
        this.dias = 0;
      }
    } else {
      this.inicio = this.inicializadorFecha();
      this.value = undefined;
      this.mostrarFecha = false;
      this.click();
    }

    this.EscribirValor();
    this.mostrarBorrar = false;
  }
  //#endregion
}
