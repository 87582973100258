import { Route } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
// TODO: canLoad deprecated use instead https://angular.io/api/router/CanMatchFn
export const routes: Route[] = [
  {
    path: '',
    redirectTo: 'login', 
    pathMatch: 'full'
  },
  {
    path: 'app',
    canActivate:[AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./components/pages/pages.routes').then((x)=> x.PagesRoutes)
  },
  {
    path: 'login',
    loadComponent: () => import("./components/login/login/login.component").then((x) => x.LoginComponent)
  },
  {
    path: 'login/r',
    loadComponent: () => import('./components/login/login/login.component').then((x) => x.LoginComponent)
  },
  {
    path: 'login/:dni/:password',
    loadComponent: () => import('./components/login/login/login.component').then((x) => x.LoginComponent)
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  }
];
