import { EColorSlider, EEstadoSlider, EModeloSlider } from '../components/slider/models/enums';
import {
    EBorderRadiusBoton,
  EColorBoton,
  EEstadoBoton,
  EFormaBoton,
  EModeloBoton,
  ETallaBoton,
} from '../components/boton/models/enums';
import { EGrosorIcono, EModeloIcono, ETallaIcono } from '../components/icono-svg/models/enums';
import { ETipoInformacion } from '../enums/informacion';
import { EPosicionNotificacion, ETipoNotificacion, IconTallaTipoNotificacion, IconTipoNotificacion, transformTipoModeloToast } from '../components/notificacion/models/enums';
import { Icon } from '../iconos/iconos';
import { EEstiloInputs, ETexto, ETipoClase, ETipoInputs, ETipoModal, transformTipoColorFuente, transformTipoModeloIcono } from '@destinux/destinux-enums';

export class EnumsLib {
  //#region boton
  public static EEstadoBoton = EEstadoBoton;
  public static EModeloBoton = EModeloBoton;
  public static EFormaBoton = EFormaBoton;
  public static EColorBoton = EColorBoton;
  public static EBorderRadiusBoton = EBorderRadiusBoton;
  public static ETallaBoton = ETallaBoton;
  public static ETexto = ETexto;
  //#endregion

  //#region icono
  public static Icon = Icon;
  public static EModeloIcono = EModeloIcono;
  public static EGrosorIcono = EGrosorIcono;
  public static ETallaIcono = ETallaIcono;
  //#endregion

  //#region slider
  public static EColorSlider = EColorSlider;
  public static EEstadoSlider = EEstadoSlider;
  public static EModeloSlider = EModeloSlider;
  //#endregion

  //#region  informacion
  public static ETipoInformacion = ETipoInformacion;
  //#endregion

  //#region notificaciones
  public static ETipoNotificacion = ETipoNotificacion;
  public static EPosicionNotificacion = EPosicionNotificacion;
  public static IconTipoNotificacion = IconTipoNotificacion;
  public static IconTallaTipoNotificacion = IconTallaTipoNotificacion;
  public static transformTipoModeloToast = transformTipoModeloToast;
  //#endregion

  //#region inputs
  public static EEstiloInputs = EEstiloInputs;
  public static ETipoInputs = ETipoInputs;
  //#endregion

  //#region modal
  public static ETipoModal = ETipoModal;
  public static transformTipoColorFuente = transformTipoColorFuente;
  public static transformTipoModeloIcono = transformTipoModeloIcono;
  //#endregion
}
