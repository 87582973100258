export enum EEstiloInputs{
    Predeterminado = "primario",
    Enfocado = "enfocado",
    Error = "error",
    Login = "login",
    ErrorDrop = "errorDrop",
    ConTextoSuperior = "con-texto-superior",
    ConTextoSuperIorError = "con-texto-superior-error",
    Drop = "drop"
}

export enum ETipoClase{
    Primario= 'primario',
    Secundario= 'secundario'
}

export enum ETipoInputs{
    text = "text",
    password = "password",
    number = "number"
}
