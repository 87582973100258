import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICabeceraDropdown, IItemDropdown } from '../../interfaces/dropdown';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BotonComponent } from '../boton/boton.component';
import { ControlValue } from '../../utiles/ControlValue';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { IconoSvgComponent } from '../icono-svg/icono-svg.component';
import { EnumsLib } from '../../utiles/enums';
import { ETallaIcono } from '../icono-svg/models/enums';

@Component({
  selector: 'destinux-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    TranslateModule,
    BotonComponent,
    IconoSvgComponent,
    FormsModule,
  ],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent extends ControlValue implements OnInit {
  //#region contentChild
  @ContentChild('selector', { static: false }) selector!: TemplateRef<any>;
  @ContentChild('items', { static: false }) items!: TemplateRef<any>;
  //#endregion

  //#region viewChild
  @ViewChild('drop') drop!: ElementRef;
  @ViewChild('menu', { read: ElementRef, static: false }) menu!: ElementRef;
  //#endregion

  //#region hostlistener
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (!this.drop?.nativeElement.contains(event.target)) {
      this.active = false;
      this.activo.emit(this.active);
    }
  }
  @HostListener('window:scroll', [])
  @HostListener('window:resize', [])
  onWindowScroll() {
    if (this.menu && this.drop) {
      const menu = this.menu.nativeElement.getBoundingClientRect();
      const drop = this.drop.nativeElement.getBoundingClientRect();
      let tamanyo = 0;
      if (this.classMenu.includes('dropend')) {
        tamanyo = drop.top + menu.height;
      } else {
        tamanyo = drop.top + menu.height + drop.height;
        this.bottom = drop.height - 2 + 'px';
      }
      if (tamanyo >= window.innerHeight) {
        this.classDireccion = 'arriba';
      } else {
        this.classDireccion = 'abajo';
      }
    }
  }
  //#endregion

  //#region hostbinding
  @HostBinding('style.--bottom') bottom: string = '0';
  //#endregion

  //#region inputs
  @Input({ required: true }) itemDropdown: IItemDropdown[] = [];
  @Input() class: string = '';
  @Input() cabecera: ICabeceraDropdown | null = null;
  @Input() classMenu: string = '';
  @Input() estilosOpciones: string = '';
  @Input() mantenerAbierto: boolean = false;
  @Input() tamanyoIcono: ETallaIcono = ETallaIcono.pq;
  //#endregion

  //#region output
  @Output() activo = new EventEmitter<boolean>();
  @Output() item = new EventEmitter<IItemDropdown>();
  //#endregion

  //#region declaración de variables
  public _texto: string = 'Seleccionar opción'; // TODO: pendiente traducciones
  public notificacion: string = '0';
  public active: boolean = false;
  public selecciono: boolean = false;
  public classDireccion: string = 'abajo';

  public enums = EnumsLib;
  //#endregion

  //#region constructor
  constructor(private route: Router) {
    super();
  }
  //#endregion

  //#region OnInit
  ngOnInit(): void {
    this.onWindowScroll();
    for (const item of this.itemDropdown) {
      if (item.notificacion) {
        this.notificacion = (
          parseInt(this.notificacion) + item.notificacion
        ).toString();
      }
    }
  }
  //#endregion

  //#region metodos

  show() {
    if (this.mantenerAbierto) {
      if (this.selecciono) {
        this.active = false;
      } else {
        this.active = true;
      }
      this.selecciono = false;
    } else {
      this.active ? (this.active = false) : (this.active = true);
    }
    this.activo.emit(this.active);
    setTimeout(() => this.onWindowScroll(), 1000);
  }
  //#endregion

  //#region metodos usados en el html
  hndRoute(item: IItemDropdown): void {
    this.value = item;
    this.EscribirValor();
    if (item.click !== null) {
      item.click();
    }
    if (item.ruta !== null) {
      this.route.navigate([item.ruta]);
      this.item.emit(item);
    } else {
      this._texto = item.nombre;
      this.item.emit(item);
    }
    this.selecciono = true;
  }
  //#endregion
}
