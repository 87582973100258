/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
  IListaPeticionesDisponibilidad,
  ItemConsultasFrecuentes,
  ItemUltimosRegistros,
  IRespuestaVueloPasajero,
  IInfoAeropuerto,
} from '../../models/autoreserva/modelosCompartidos';
import { ServiceHttpService } from '../service-http.service';
import { environment } from '@env/environment';
import { ETipoProducto } from '../../enums/autoreserva/comun';
import { InfoAutorizador } from '../../models/autorizadores/autorizadores';

@Injectable({
  providedIn: 'root',
})
export class AutoreservaService {
  
  //#region declaracion de variables
  private _listaPeticionesDisponibilidad = new Subject<IListaPeticionesDisponibilidad>();
  listaPeticionesDisponibilidad$ = this._listaPeticionesDisponibilidad.asObservable();

  private _registroSeleccionado = new Subject<ItemUltimosRegistros>();
  registroSeleccionado$ = this._registroSeleccionado.asObservable();

  private _ubicacionFrecuenteSeleccionada = new Subject<ItemConsultasFrecuentes>();
  ubicacionFrecuenteSeleccionada$ = this._ubicacionFrecuenteSeleccionada.asObservable();

  private _residenteObligatorio = new BehaviorSubject<boolean>(false);
  residenteObligatorio$ = this._residenteObligatorio.asObservable();

  public permiteSaltarReglas: boolean = false;
  private _infoAeropuertos = new BehaviorSubject<IInfoAeropuerto[]>([]);
  infoAeropuertos$ = this._infoAeropuertos.asObservable();

  #autorizadoresViajes = new BehaviorSubject<InfoAutorizador[]>([]);
  autorizadoresViajes$ = this.#autorizadoresViajes.asObservable();

  private _idEmpresaViajeros = new BehaviorSubject<number | null>(null);
  idEmpresaViajeros$ = this._idEmpresaViajeros.asObservable();
  //#endregion

  //#region constructor
  constructor(protected _httpService: ServiceHttpService) {}
  //#endregion

  //#region metodos getter and setter
  setListaPeticionesDisponibilidad( lista: IListaPeticionesDisponibilidad): void {
    this._listaPeticionesDisponibilidad.next({ ...lista });
  }

  seleccionarUbicacionFrecuente(item: ItemConsultasFrecuentes): void {
    this._ubicacionFrecuenteSeleccionada.next({ ...item });
  }

  seleccionarRegistro(item: ItemUltimosRegistros): void {
    this._registroSeleccionado.next({ ...item });
  }

  setResidenteObligatorio(value: boolean): void {
    this._residenteObligatorio.next(value);
  }

  setDatosInfoAeropuertosUltimasBusquedas(datosAeropuertos: IInfoAeropuerto[]):void {
    this._infoAeropuertos.next(datosAeropuertos);
  }

  getDatosInfoAeropuertosUltimasBusquedas():IInfoAeropuerto[] {
    return this._infoAeropuertos.value;
  }

  setAutorizadoresViajes(autorizadores: InfoAutorizador[]) {
    this.#autorizadoresViajes.next(autorizadores);
  }

  getAutorizadoresViajes():InfoAutorizador[] {
    return this.#autorizadoresViajes.getValue();
  }

  setEmpresaViajeros(idEmpresa: any):void {
    this._idEmpresaViajeros.next(idEmpresa);
  }

  getEmpresaViajeros():number | null{
    return this._idEmpresaViajeros.getValue();
  }
  //#endregion

  //#region metodos htttpService
  getGeocodeAPI(
    cadena: string,
    coordenadas: string,
    codigoISO: string,
    lenguaje: string,
    controlErrores: boolean = false
  ): Observable<any> {
    return this._httpService.getApi(
      `Lugares?cadena=${cadena}&coordenadas=${coordenadas}&lang=${lenguaje}&codigo=${codigoISO}`,
      environment.baseJsonPruebas + 'autoreserva/busqueda_lugar.json',null,controlErrores
    );
  }

  postSeleccionarViajeros(peticion: any): Observable<any> {
    if (!peticion.tipo) {
      peticion = peticion.map((viajero: any) => ({
        guid: viajero.guid,
        tipo: 2,
      }));
    }
    return this._httpService.postApi(
      'SeleccionarViajeros',
      peticion,
      environment.baseJsonPruebas + 'autoreserva/seleccionar_viajeros.json'
    );
  }

  getVerOpciones(): Observable<any> {
    return this._httpService.getApi(
      'DatosReserva/VerOpciones',
      environment.baseJsonPruebas + 'autoreserva/opciones_viajeros.json'
    );
  }

  setDatosReserva(datos: any): Observable<any> {
    return this._httpService.postApi('DatosReserva/Seleccionar', datos);
  }

  /**
   * Metodo que permite obtener informacion sobre el vuelo del pasajero titular de la reserva
   * @param codigoPasajero identificador del pasajero en octopus
   */
  InformacionVueloPasajero(codigoPasajero: string): Observable<IRespuestaVueloPasajero> {
    return this._httpService.get<IRespuestaVueloPasajero>(`${environment.baseJsonPruebas}autoreserva/transfer/respuesta_informacion_vuelo.json`);
  }

  getListaPeticionesDisponibilidad(
    tipo: ETipoProducto
  ): Observable<IListaPeticionesDisponibilidad> {
    const url = `ListarPeticionesDisponibilidad?tipoProducto=${tipo}`;
    const tipoTexto = ETipoProducto[tipo].toLowerCase();

    return this._httpService.getApi<IListaPeticionesDisponibilidad>(
      url,
      `${environment.baseJsonPruebas}autoreserva/${tipoTexto}/lista_peticiones_disponibilidad.json`
    );
  }

  getReferenciasEspecificas(): Observable<any> {
    return this._httpService.getApi(
      'CorreosReferencias',
      environment.baseJsonPruebas +
        'autoreserva/correosreferencias/correosreferencias.json'
    );
  }

  crearProyecto(datosProyecto: { Codigo: string; Descripcion: string; }) {
    return this._httpService.postApi('DatosReserva/NuevoProyecto', datosProyecto, 'autoreserva/proyectos/nuevoProyecto.json');
  }
  //#endregion

  //#region metodos
  finalizarReserva(): void {
    localStorage.removeItem('datosDashboardAdministracion');
    localStorage.removeItem('datosDashboardAutoreserva');
  }

  montarReferencia(tipoProyecto: any, referencia: any, refacturar: any): string{
    refacturar = JSON.parse(refacturar.toLowerCase());

    let campoReferencia = "";

    if (tipoProyecto == "Interno") {
      campoReferencia = referencia.ID;
    } else{
      campoReferencia =  `${((refacturar == true) ? "Facturable " : "")}${referencia.ID}`
    }

    return campoReferencia;
  }
  //#endregion
}
