<div [class]="class + ' ' + classDireccion" class="dropdown" [ngClass]="{'show': active}"  (click)="show()" #drop>
  <ng-template #defaultTemplate>
    <destinux-boton [texto]="_texto"></destinux-boton>
  </ng-template>
  <ng-container [ngTemplateOutlet]="selector || defaultTemplate"></ng-container>
  <div #menu *ngIf="itemDropdown.length > 0" class="dropdown-menu" [class]="classMenu + ' ' + classDireccion">
    <div *ngIf="cabecera" class="g-flex g-flex--align-items-center g-padding-top-xp g-padding-horizontal-xp g-padding-bottom-4">
      <destinux-icono-svg *ngIf="cabecera.imgCabecera" [talla]="enums.ETallaIcono.md" [urlIcono]="cabecera.imgCabecera" 
      class="g-padding-right-xxp"></destinux-icono-svg>
      <span *ngIf="cabecera.titulo">{{ cabecera.titulo | translate | uppercase }}</span>
    </div>
    <div class="g-padding-bottom-xxp">
      <a *ngFor="let item of itemDropdown" (click)="hndRoute(item)" 
        class="g-flex g-flex--align-items-center g-flex--justify-content-space-between" [class]="estilosOpciones" [ngClass]="{'g-cursor-none opcion-no-elegible':item.anulaEventosPuntero}">
        <div class="g-flex g-align-items-center">
          <destinux-icono-svg *ngIf="item.svg" [urlIcono]="item.svg" [talla]="tamanyoIcono"></destinux-icono-svg>
          <img *ngIf="item.img" [src]="item.img" class="g-height-xp g-margin-right-xxp"/>
          <ng-container [ngTemplateOutlet]="items || itemDefault" [ngTemplateOutletContext]="{ $implicit: item}"></ng-container>
          <ng-template #itemDefault>
            <span class="g-font--parrafo-pq" [class]="item.class"> {{item.nombre | translate}}</span>
          </ng-template>
        </div>
        <span *ngIf="item.notificacion && item.notificacion > 0" class="notificacion g-font--parrafo-xp-negrita">
          {{item.notificacion}}
        </span>
      </a>
    </div>
  </div>
</div>